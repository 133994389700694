import React from "react";
import ReactDOM from "react-dom/client";
import {
  initializeHoneycomb,
  injectOpenTelemetryAttributeStoreIntoWindow,
} from "@evg-ui/lib/utils/observability";
import { toEscapedRegex } from "@evg-ui/lib/utils/string";
import { initializeErrorHandling } from "components/ErrorHandling";
import { evergreenURL, isDevelopmentBuild } from "utils/environmentVariables";
import App from "./App";

initializeErrorHandling();
initializeHoneycomb({
  backendURL: toEscapedRegex(evergreenURL || ""),
  debug: isDevelopmentBuild(),
  endpoint:
    process.env.REACT_APP_HONEYCOMB_ENDPOINT ||
    "https://otel-collector.prod.corp.mongodb.com/v1/traces",
  ingestKey:
    process.env.REACT_APP_HONEYCOMB_INGEST_KEY ||
    "hcaik_01j8117thr63jqdh8k12m4b1xy0emmt6468h2aaytm1c0zpefp2e8gysrg",
  serviceName: "parsley",
});
injectOpenTelemetryAttributeStoreIntoWindow();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
